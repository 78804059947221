#news {

	// news ------------------------------------------------
	.news {
		width: 100%;
		max-width: $contents-width;
		margin: 0 auto 135px;
		text-align: center;

		@include max_screen($break-point) {
			width: percentage($contents-width--sp / 750px);
			margin-bottom: 70px;
		}

		&__list {
			margin: 0 auto;
			text-align: left;

			&__item {
				padding-bottom: 90px;
				margin-top: 50px;
				border-bottom: 1px dashed $color-gray;

				&:first-child {
					margin-top: 0;
				}

				.date {
					font-size: 12px;
					font-weight: 500;
					line-height: 1;
					letter-spacing: .1em;
					margin-bottom: 14px;
				}

				.subject {
					font-size: 16px;
					letter-spacing: .1em;
					@include txt_link_opacity;
					font-weight: 300;
					line-height: 1.5;
					margin-bottom: 33px;
				}

				.entry-body {
					font-size: 12px;
					letter-spacing: .1em;
					font-weight: 300;
					line-height: percentage(22 / 12);
				}

				.read-more {
					display: none;
				}
			}

			@include max_screen($break-point) {
				&__item {
					padding-bottom: 34px;
					margin-top: 34px;

					.date {
						margin-bottom: 10px;
					}

					.subject {
						margin-bottom: 15px;
						font-size: 14px;
					}

					.entry-body {
						display: none;
					}

					.read-more {
						display: block;
						font-family: $font-en;
						font-size: 15px;
						letter-spacing: .2em;
						@include txt_link_opacity(underline, $color-gray, $color-gray);
					}
				}
			}
		}

		&.single {
			margin-bottom: 90px;

			.news__list {
				&__item {
					.subject {
						margin-bottom: 40px;
					}

					.entry-body {
						display: block;
						font-size: 13px;
						letter-spacing: .075em;
						line-height: percentage(56 / 26);
					}
				}
			}
		}

		.wp-pagenavi {
			line-height: 1;
			font-family: $font-en;
			font-size: 14px;
			letter-spacing: .2em;
			text-indent: .2em;
			color: $color-gray;
			@include txt_link_opacity(none, $color-gray, $color-gray);
			display: flex;
			justify-content: center;
			margin-top: 37px;

			a,
			.current {
				width: 36px;
				position: relative;

				&:after {
					content: "";
					height: 13px;
					width: 1px;
					background: $color-gray;
					position: absolute;
					top: 1px;
					right: 0;
				}

				&:last-child {
					&:after {
						display: none;
					}
				}
			}

			.current {
				font-weight: bold;
			}

			@include max_screen($break-point) {
				display: none;
			}
		}

		&__more-btn {
			display: none;

			@include max_screen($break-point) {
				display: block;
				font-family: $font-en;
				@include txt_link_color(none, $color-dark-gray, $color-dark-gray);
				line-height: 1;
				font-size: 15px;
				letter-spacing: .2em;
				text-indent: .2em;
				margin-top: 40px;
			}
		}

		.single-pager {
			width: 100%;
			display: flex;
			justify-content: center;
			text-align: center;
			//color: #999;
			font-family: $font-en;
			@include txt_link_opacity(none, $color-gray);
			font-size: 13px;
			line-height: 1;
			letter-spacing: .2em;
			margin-top: 37px;

			@include max_screen($break-point) {
				margin-top: 25px;
				font-size: 14px;
			}

			&__item {
				padding: 0 13px 0 0;
				position: relative;
				margin-right: 13px;

				&:after {
					content: "";
					width: 1px;
					height: 13px;
					background: $color-gray;
					position: absolute;
					top: 0;
					right: 0;
				}

				&:last-child {
					padding: 0;
					margin: 0;

					&:after {
						display: none;
					}
				}

				@include max_screen($break-point) {
					padding: 0 30px 0 0;
					margin-right: 30px;

					&:after {
						top: 1px;
						height: 14px;
					}
				}
			}
		}
	}

}
