@charset "utf-8";

.footer {
	text-align: center;

	&__copyright {
		font-family: $font-en;
		font-size: 11px;
		letter-spacing: .2em;
		text-indent: .2em;
		color: $color-light-gray;
		padding-bottom: 16px;
		line-height: 1;
	}
}
