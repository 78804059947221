#portfolio {

	// heading ------------------------------------------------
	.page-heading {
		margin-bottom: 38px;
	}

	.portfolio-category {
		display: none;

		@include max_screen($break-point) {
			display: block;
			font-size: 20px;
			letter-spacing: 0.2em;
			text-indent: 0.2em;
			font-weight: 400;
			text-align: center;
			margin-bottom: 45px;
			font-family: $font-en;
			line-height: 1;
			color: $color-gray;
		}
	}

	// category-select ------------------------------------------------
	.category-select {
		width: 100%;
		height: 45px;
		border-top: 1px solid $color-light-gray;
		border-bottom: 1px solid $color-light-gray;
		margin-bottom: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;

		@include min_screen($break-point + 1px) {
			display: none;
		}

		&__display {
			font-family: $font-en;
			font-size: 15px;
			letter-spacing: .2em;
			text-indent: .2em;
			color: $color-gray;
			position: relative;

			&:after {
				content: "";
				background: url("/wp/wp-content/themes/main/assets/img/common/icon_thick-arw.svg") no-repeat;
				background-size: 9px auto;
				transform: rotate(90deg);
				width: 9px;
				height: 16px;
				position: absolute;
				top: 3px;
				right: -65px;
			}
		}

		&__selectbox {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: #ddd;
			opacity: 0;
		}
	}


	// portfolio__list ------------------------------------------------
	.portfolio__list {
		width: percentage($contents-width--sp / 750px);
		margin: 0 auto 100px;

		&__item {
			margin-bottom: 25px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	// portfolio__slider ------------------------------------------------
	.portfolio__slider {
		width: 100%;
		max-width: $contents-width;
		margin: 0 auto 80px;

		.swiper-slide {
			text-align: center;

			img {
				max-height: 600px;
			}
		}
	}

	// portfolio-thumb ------------------------------------------------
	.portfolio-thumb {
		width: 100%;
		max-width: 700px;
		margin: 0 auto 155px;
		position: relative;

		&__slider {
			width: 100%;
			max-width: 620px;
			margin: 0 auto;

			&__prev-btn {
				background: url("/wp/wp-content/themes/main/assets/img/common/icon_thick-arw.svg") no-repeat;
				background-size: 13px 24px;
				width: 13px;
				height: 24px;
				position: absolute;
				top: 50%;
				left: 0;
				transform: rotate(180deg) translateY(50%);
				cursor: pointer;
			}

			&__next-btn {
				background: url("/wp/wp-content/themes/main/assets/img/common/icon_thick-arw.svg") no-repeat;
				background-size: 13px 24px;
				width: 13px;
				height: 24px;
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
				cursor: pointer;
			}

			.swiper-slide {
				height: 120px;
				text-align: center;
				cursor: pointer;
				@include transition;
				display: flex;
				align-items: center;
				justify-content: center;

				&:hover {
					opacity: .5;
				}

				img {
					//height: 120px;
					width: auto;
				}
			}
		}
	}
}
