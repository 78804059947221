@charset "utf-8";

.header {
	width: 100%;
	background: $color-white;
	padding: 70px 0 0;
	font-family: $font-en;
	box-sizing: border-box;
	z-index: 100;
	position: relative;
	margin-bottom: 90px;

	@include max_screen($break-point) {
		height: 110px;
		padding-top: 37px;
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(#fff, .8);
	}

	&__logo {
		width: 144px;
		margin: 0 auto 44px;

		@include max_screen($break-point) {
			width: 93px;
			margin: 0 auto;
		}
	}

	&__navi {

		&__list {
			width: 100%;
			display: flex;
			justify-content: center;
			background: $color-white;

			&__item {
				font-size: 12px;
				letter-spacing: .2em;
				text-indent: .2em;
				@include txt_link_color(none, $color-gray, $color-gray);
				position: relative;
				margin-right: 32px;
				padding-bottom: 7px;

				&:last-child {
					margin-right: 0;
				}

				&.off {
					pointer-events: none;
					opacity: .5;
				}

				&:after {
					content: "";
					width: 100%;
					height: 0;
					border-bottom: 1px dashed $color-gray;
					position: absolute;
					bottom: 0;
					left: 0;
					opacity: 0;
					@include transition;
				}

				@include min_screen($break-point + 1px) {
					&:hover {
						&:after {
							opacity: 1;
						}

						.category-list {
							opacity: 1;
							pointer-events: auto;
						}
					}
				}

				.category-list {
					position: absolute;
					top: 100%;
					left: 50%;
					transform: translateX(-50.5%);
					background: rgba($color-white, .8);
					padding: 20px;
					box-sizing: border-box;
					text-align: center;
					opacity: 0;
					pointer-events: none;
					@include transition;

					&__item {
						line-height: 1;
						margin-bottom: 14px;
						@include txt_link_opacity(none, $color-gray, $color-gray);

						&.back {
							display: none;
						}

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}

		&__close-btn {
			display: none;
		}

		@include max_screen($break-point) {
			width: 275px;
			height: 100%;
			overflow-y: auto;
			background: $color-white;
			position: fixed;
			top: 0;
			right: 0;
			border-left: 1px solid #ddd;
			@include transition;
			transform: translateX(280px);
			text-align: right;
			z-index: 1000;

			&__list {
				display: block;
				padding: 60px 50px 60px 0;

				&__item {
					font-size: 14px;
					text-indent: 0;
					padding: 0;
					margin: 0 0 50px;
					position: static;

					&:last-child {
						margin: 0;
					}

					&:after {
						display: none;
					}

					.category-list {
						border-left: 1px solid #ddd;
						width: 200px;
						height: 100%;
						position: absolute;
						top: 0;
						right: 0;
						left: auto;
						background: $color-white;
						padding: 60px 50px 60px;
						pointer-events: auto;
						z-index: 1000;
						opacity: 1;
						text-align: right;
						@include transition;
						transform: translateX(220px);

						&__item {
							font-size: 14px;
							text-indent: 0;
							margin-bottom: 50px;

							&.back {
								display: block;
								position: relative;

								&:after {
									content: "";
									width: 9px;
									height: 13px;
									background: url("/wp/wp-content/themes/main/assets/img/common/icon_navi-arw.svg") no-repeat;
									background-size: 100% auto;
									position: absolute;
									top: 50%;
									right: -16px;
									transform: translateY(-50%);
								}
							}
						}
					}
				}
			}

			&__close-btn {
				line-height: 1;
				font-size: 12px;
				color: $color-gray;
				letter-spacing: .1em;
				position: absolute;
				top: 33px;
				left: 22px;
				display: block;

				img {
					width: 24px;
					margin: 0 0 5px 5px;
					display: block;
				}
			}
		}
	}

	&__menu-btn {
		display: none;

		@include max_screen($break-point) {
			width: 33px;
			line-height: 1;
			font-size: 12px;
			color: $color-gray;
			letter-spacing: .1em;
			position: absolute;
			top: 42px;
			right: 35px;
			display: block;

			img {
				margin-bottom: 7px;
			}
		}
	}
}


@include min_screen($break-point + 1px) {
	body.header-small {
		.header {
			height: 80px;
			padding: 32px 0 0;
			position: fixed;
			top: 0;
			left: 0;
			margin-bottom: 0;

			&__logo {
				width: 115px;
				margin-bottom: 30px;
				display: none;
			}
		}
	}
}

@include max_screen($break-point) {
	body.header-small {
		.header {
			height: 70px;
			padding: 15px 0 0;

			&__logo {
				width: 84px;
			}

			.header__menu-btn {
				top: 19px;
			}
		}
	}
}

@include max_screen($break-point) {
	body.navi-opened {
		.header__navi {
			transform: none;
		}

		&.category-opened {
			.header__navi {
				&__list {
					&__item {
						.category-list {
							transform: none;
						}
					}
				}
			}
		}
	}
}

#portfolio {
	.header__navi__list__item:nth-child(1) {
		&:after {
			opacity: 1;
		}
	}
}

#news {
	.header__navi__list__item:nth-child(2) {
		&:after {
			opacity: 1;
		}
	}
}

#biography {
	.header__navi__list__item:nth-child(3) {
		&:after {
			opacity: 1;
		}
	}
}

#makelesson {
	.header__navi__list__item:nth-child(4) {
		&:after {
			opacity: 1;
		}
	}
}

#contact {
	.header__navi__list__item:nth-child(5) {
		&:after {
			opacity: 1;
		}
	}
}
