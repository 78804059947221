#biography {

	// biography ------------------------------------------------
	.biography {
		width: 100%;
		max-width: $contents-width;
		margin: 0 auto 316px;

		@include max_screen($break-point) {
			width: percentage($contents-width--sp/750px);
			margin-bottom: 97px;
		}

		&__lyar{
			margin-bottom: 191px;

			@include max_screen($break-point) {
				margin-bottom: 94px;
			}

			.ttl{
				font-size: 17px;
				letter-spacing: 0.1em;
				line-height: 1;
				font-weight: 300;
				margin-bottom: 29px;

				@include max_screen($break-point) {
					font-size: 16px;
					letter-spacing: 0.075em;
				}
			}

			.career{

				th{
					font-size: 12px;
					letter-spacing: 0.1em;
					line-height: percentage(27/12);
					font-weight: 300;
					color: $color-black;
					vertical-align: top;
					width: 92px;
					text-align: left;

					@include max_screen($break-point) {
						width: 100%;
						font-size: 13px;
						letter-spacing: 0.075em;
						display: block;
					}
				}

				td{
					font-size: 12px;
					letter-spacing: 0.1em;
					line-height: percentage(27/12);
					font-weight: lighter;
					color: $color-black;

					@include max_screen($break-point) {
						font-size: 13px;
						letter-spacing: 0.075em;
						line-height: percentage(56/26);
						width: 100%;
						display: block;
						padding-bottom: 26px;
					}

					&.born{
						padding-bottom: 25px;

						@include max_screen($break-point) {
							padding-bottom: 26px;
						}
					}
				}
			}
		}

		&__detail{
			margin-bottom: 92px;

			@include max_screen($break-point) {
				margin-bottom: 95px;
			}

			&:last-of-type{
				margin-bottom: 0;
			}

			.ttl{
				font-size: 15px;
				letter-spacing: 0.1em;
				line-height: 1;
				font-weight: 300;
				margin-bottom: 30px;

				@include max_screen($break-point) {
					font-size: 16px;
					letter-spacing: 0.075em;
					margin-bottom: 33px;
				}
			}

			.txt{
				font-size: 12px;
				letter-spacing: 0.1em;
				line-height: percentage(27/12);
				font-weight: 300;

				@include max_screen($break-point) {
					font-size: 13px;
					letter-spacing: 0.075em;
					line-height: percentage(56/26);
				}

				&.txt-mb{
					margin-bottom: 28px;

					@include max_screen($break-point) {
						margin-bottom: 31px;
					}
				}
			}
		}
	}

}
