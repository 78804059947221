@charset "utf-8";

.container {
	width: 100%;

	@include min_screen($break-point + 1px) {
		@at-root .header-small .container {
			padding-top: 80px + 215px;
		}
	}

	@include max_screen($break-point) {
		padding-top: 145px;
	}
}

.page-heading {
	display: none;

	@include max_screen($break-point) {
		display: block;
		font-size: 20px;
		letter-spacing: 0.2em;
		text-indent: 0.2em;
		font-weight: 400;
		text-align: center;
		margin-bottom: 49px;
		font-family: $font-en;
		color: $color-gray;

		span{
			border-bottom: 1px dashed $color-gray;
			display: inline-block;
			padding-bottom: 7px;
		}
	}
}
