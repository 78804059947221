#top {

	// portfolio ------------------------------------------------
	.portfolio {
		width: 100%;
		max-width: $contents-width;
		margin: 0 auto 105px;

		&__slider {
			width: 100%;
			height: 600px;
			@include txt_link_opacity;

			.swiper-slide {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				background: $color-white;
			}
		}

		@include max_screen($break-point) {
			width: percentage(550 / 750);
			margin-bottom: 50px;

			&__slider {
				height: 390px;

				img {
					max-height: 390px;
				}
			}
		}
	}

	// news ------------------------------------------------
	.news {
		width: 100%;
		max-width: $contents-width;
		margin: 0 auto 110px;
		text-align: center;

		@include max_screen($break-point) {
			width: percentage($contents-width--sp / 750px);
			margin-bottom: 70px;
		}

		&__heading {
			font-family: $font-en;
			letter-spacing: .2em;
			text-indent: .2em;
			line-height: 1;
			font-size: 15px;
			width: 90px;
			border: 1px dashed $color-gray;
			border-width: 1px 0;
			padding: 13px 0;
			margin: 0 auto 25px;
			color: $color-dark-gray;

			@include max_screen($break-point) {
				font-size: 18px;
				padding: 12px 0;
				margin-bottom: 36px;
			}
		}

		&__list {
			display: inline-block;
			margin: 0 auto;
			text-align: left;

			&__item {
				display: flex;
				font-size: 12px;
				letter-spacing: .05em;
				margin-bottom: 3px;
				color: $color-gray;

				&:last-child {
					margin-bottom: 0;
				}

				.date {
					width: 90px;
					min-width: 90px;
				}

				.subject {
					@include txt_link_opacity(none, $color-gray, $color-gray);
				}
			}

			@include max_screen($break-point) {
				display: block;

				&__item {
					display: block;
					margin-bottom: 23px;

					.date {
						line-height: 1;
						margin-bottom: 4px;
					}

					.subject {
						line-height: percentage(42 / 24);
					}
				}
			}
		}

		&__more-btn {
			font-family: $font-en;
			@include txt_link_color(underline, $color-dark-gray, $color-dark-gray);
			line-height: 1;
			font-size: 15px;
			letter-spacing: .2em;
			text-indent: .2em;
			margin-top: 45px;

			@include max_screen($break-point) {
				@include txt_link_color(none, $color-dark-gray, $color-dark-gray);
			}
		}
	}

}
