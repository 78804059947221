#contact {

	// conatct ------------------------------------------------
	.contact {
		width: 100%;
		max-width: $contents-width;
		margin: 0 auto 245px;

		@include max_screen($break-point) {
			width: percentage($contents-width--sp/750px);
			margin-bottom: 98px;
		}
	}

	.txt-jp{
		font-size: 12px;
		letter-spacing: 0.1em;
		font-weight: 300;
		line-height: percentage(27/12);
		margin-bottom: 27px;

		@include max_screen($break-point) {
			font-size: 13px;
			line-height: percentage(57/26);
			letter-spacing: 0.075em;
			margin-bottom: 15px;
		}
	}

	.txt-en{
		font-size: 12px;
		letter-spacing: 0.1em;
		font-weight: 300;
		line-height: percentage(27/12);
		margin-bottom: 54px;

		@include max_screen($break-point) {
			font-size: 13px;
			line-height: percentage(57/26);
			letter-spacing: 0.075em;
			margin-bottom: 28px;
		}
	}

	.txt-request{
		font-size: 12px;
		letter-spacing: 0.1em;
		font-weight: 300;
		line-height: percentage(27/12);
		margin-bottom: 40px;

		@include max_screen($break-point) {
			font-size: 13px;
			line-height: percentage(57/26);
			letter-spacing: 0.075em;
			margin-bottom: 41px;
		}
	}

	.wpcf7 {
		padding-top: 180px;
		margin-top: -180px;
	}

	.form__item{
		font-size: 12px;
		letter-spacing: 0.1em;
		font-weight: 300;
		line-height: percentage(27/12);
		border-top: 1px dashed $color-gray;
		padding: 20px 8px 0 8px;
		box-sizing: border-box;

		&.last{
			border-bottom: 1px dashed $color-gray;
		}

		@include max_screen($break-point) {
			font-size: 13px;
			letter-spacing: 0.075em;
			line-height: percentage(56/26);
			padding: 17px 0 17px 8px;
		}

		.heading{
			line-height: 1;

			&.required {
				&:after {
					content: "＊";
					color: #f00;
				}
			}

			@include max_screen($break-point) {
				margin-bottom: 16px;
			}
		}

		.input {
			margin-top: 6px;
			padding-bottom: 10px;

			.wpcf7-not-valid-tip {
				display: block;
				color: #f00;
				font-weight: 500;
			}

			&.checkbox {
				margin-top: 35px;
				padding-bottom: 40px;

				.wpcf7-list-item {
					margin-bottom: 11px;
					display: block;

					&:last-child {
						margin-bottom: 0;
					}

					input[type="checkbox"] {
						position: absolute;
						top: 4px;
						left: 0;
					}

					label {
						display: block;
						width: 100%;
						position: relative;
						line-height: 1.5;
						padding-left: 24px;
						cursor: pointer;
					}
				}
			}

			input[type="text"] {
				width: 100%;
				height: 34px;
				cursor: pointer;
				padding-left: 10px;

				@include max_screen($break-point) {
					font-size: 16px;
				}
			}

			textarea{
				width: 100%;
				height: 175px;
				cursor: pointer;

				@include max_screen($break-point) {
					font-size: 16px;
				}
			}

		}
	}

	input[type=submit]{
		font-size: 21px;
		letter-spacing: 0.2em;
		text-indent: 0.2em;
		font-family: $font-en;
		color: $color-white;
		background: $color-light-gray;
		border-radius: 6px;
		width: 230px;
		height: 45px;
		line-height: 1;
		box-sizing: border-box;
		display: block;
		margin: 49px auto 0 auto;

		@include max_screen($break-point) {
			margin: 24px auto 0 auto;
			font-size: 15px;
			font-weight: 500;
			height: 35px;
			width: percentage(500px/$contents-width--sp);
			max-width: 500px;
			border-radius: 4px;
		}

		&:hover{
			opacity: .7;
			@include transition(.2);
		}
	}

	.screen-reader-response {
		display: none;
	}

	.wpcf7-validation-errors {
		margin-top: 50px;
		width: 100%;
		color: #f00;
		box-sizing: border-box;
		padding: 15px;
		text-align: center;
		font-size: 12px;
		font-weight: 500;
		letter-spacing: .05em;
		border: none!important;
	}

	.wpcf7-mail-sent-ok {
		border: 1px solid $color-gray;
		margin-top: 50px;
		width: 100%;
		box-sizing: border-box;
		padding: 15px;
		text-align: center;
		font-size: 15px;

		display: none!important;
	}

	.send-ok-msg {
		display: none;

		@at-root .sent-ok .send-ok-msg {
			display: block!important;
		}
	}

	.ajax-loader {
		display: none!important;
	}
}
